module.exports = global.GLOBAL_CONSTANT = {
    // "API_URL": "https://api.beta.cybersmarties.co.nz",
    // "API_URL": "https://api.cybersmarties.co.nz",
    // "API_URL": "https://api.cybersmarties.ie",
    // "API_URL": "http://localhost:4000",
    // "TIME_ZONE": "Pacific/Auckland",
    // "TIME_ZONE": "Europe/Dublin",
    // "API_URL": "https://api.beta.cybersmartiesusa.com",
    // "TIME_ZONE": "America/New_York",
    // "API_URL": "https://api.cybersmarties.com.tr",
    // "TIME_ZONE": "Asia/Istanbul",
    "API_URL": "https://api.cybersmartiesusa.com",
    "TIME_ZONE": "America/Los_Angeles",
}